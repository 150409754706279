import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/phorkit/phorkit/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { PhorkIcon } from 'icons/PhorkIcon';
import { Divider } from 'components/Divider';
import { Paper } from 'components/Paper';
import { Rhythm } from 'components/Rhythm';
import { Typography } from 'components/Typography';
import { PageTitle } from 'docs/helpers/PageTitle';
import { ThemeWrapper } from 'docs/helpers/ThemeWrapper';
import { IconStatusBubble } from '../index';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageTitle src="compositions/StatusBubble" title="IconStatusBubble" mdxType="PageTitle" />
    <h2 {...{
      "id": "neutral-status-bubble"
    }}>{`Neutral status bubble`}</h2>
    <Playground __position={1} __code={'<ThemeWrapper>\n  <IconStatusBubble\n    color=\"neutral\"\n    header={\n      <React.Fragment>\n        <Typography weight=\"bold\">Something cool happened</Typography>\n        <Typography volume=\"quiet\">Yesterday</Typography>\n      </React.Fragment>\n    }\n    icon={<PhorkIcon scale=\"large\" />}\n  >\n    <Paper color=\"primary\">\n      <Rhythm p={4}>Hello world</Rhythm>\n    </Paper>\n  </IconStatusBubble>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PhorkIcon,
      Divider,
      Paper,
      Rhythm,
      Typography,
      PageTitle,
      ThemeWrapper,
      IconStatusBubble,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <IconStatusBubble color="neutral" header={<React.Fragment>
          <Typography weight="bold" mdxType="Typography">Something cool happened</Typography>
          <Typography volume="quiet" mdxType="Typography">Yesterday</Typography>
        </React.Fragment>} icon={<PhorkIcon scale="large" mdxType="PhorkIcon" />} mdxType="IconStatusBubble">
      <Paper color="primary" mdxType="Paper">
        <Rhythm p={4} mdxType="Rhythm">Hello world</Rhythm>
      </Paper>
    </IconStatusBubble>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "primary-status-bubble"
    }}>{`Primary status bubble`}</h2>
    <Playground __position={2} __code={'<ThemeWrapper>\n  <IconStatusBubble\n    color=\"primary\"\n    header={\n      <React.Fragment>\n        <Typography weight=\"bold\">Something cool happened</Typography>\n        <Typography volume=\"quiet\">Yesterday</Typography>\n      </React.Fragment>\n    }\n    icon={<PhorkIcon scale=\"large\" />}\n  >\n    <Paper color=\"primary\">\n      <Rhythm p={4}>Hello world</Rhythm>\n    </Paper>\n  </IconStatusBubble>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PhorkIcon,
      Divider,
      Paper,
      Rhythm,
      Typography,
      PageTitle,
      ThemeWrapper,
      IconStatusBubble,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <IconStatusBubble color="primary" header={<React.Fragment>
          <Typography weight="bold" mdxType="Typography">Something cool happened</Typography>
          <Typography volume="quiet" mdxType="Typography">Yesterday</Typography>
        </React.Fragment>} icon={<PhorkIcon scale="large" mdxType="PhorkIcon" />} mdxType="IconStatusBubble">
      <Paper color="primary" mdxType="Paper">
        <Rhythm p={4} mdxType="Rhythm">Hello world</Rhythm>
      </Paper>
    </IconStatusBubble>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "success-status-bubble"
    }}>{`Success status bubble`}</h2>
    <Playground __position={3} __code={'<ThemeWrapper>\n  <IconStatusBubble\n    color=\"success\"\n    header={\n      <React.Fragment>\n        <Typography weight=\"bold\">Something cool happened</Typography>\n        <Typography volume=\"quiet\">Yesterday</Typography>\n      </React.Fragment>\n    }\n    icon={<PhorkIcon scale=\"large\" />}\n  >\n    <Paper color=\"primary\">\n      <Rhythm p={4}>Hello world</Rhythm>\n    </Paper>\n  </IconStatusBubble>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PhorkIcon,
      Divider,
      Paper,
      Rhythm,
      Typography,
      PageTitle,
      ThemeWrapper,
      IconStatusBubble,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <IconStatusBubble color="success" header={<React.Fragment>
          <Typography weight="bold" mdxType="Typography">Something cool happened</Typography>
          <Typography volume="quiet" mdxType="Typography">Yesterday</Typography>
        </React.Fragment>} icon={<PhorkIcon scale="large" mdxType="PhorkIcon" />} mdxType="IconStatusBubble">
      <Paper color="primary" mdxType="Paper">
        <Rhythm p={4} mdxType="Rhythm">Hello world</Rhythm>
      </Paper>
    </IconStatusBubble>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "warning-status-bubble"
    }}>{`Warning status bubble`}</h2>
    <Playground __position={4} __code={'<ThemeWrapper>\n  <IconStatusBubble\n    color=\"warning\"\n    header={\n      <React.Fragment>\n        <Typography weight=\"bold\">Something cool happened</Typography>\n        <Typography volume=\"quiet\">Yesterday</Typography>\n      </React.Fragment>\n    }\n    icon={<PhorkIcon scale=\"large\" />}\n  >\n    <Paper color=\"primary\">\n      <Rhythm p={4}>Hello world</Rhythm>\n    </Paper>\n  </IconStatusBubble>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PhorkIcon,
      Divider,
      Paper,
      Rhythm,
      Typography,
      PageTitle,
      ThemeWrapper,
      IconStatusBubble,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <IconStatusBubble color="warning" header={<React.Fragment>
          <Typography weight="bold" mdxType="Typography">Something cool happened</Typography>
          <Typography volume="quiet" mdxType="Typography">Yesterday</Typography>
        </React.Fragment>} icon={<PhorkIcon scale="large" mdxType="PhorkIcon" />} mdxType="IconStatusBubble">
      <Paper color="primary" mdxType="Paper">
        <Rhythm p={4} mdxType="Rhythm">Hello world</Rhythm>
      </Paper>
    </IconStatusBubble>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "danger-status-bubble"
    }}>{`Danger status bubble`}</h2>
    <Playground __position={5} __code={'<ThemeWrapper>\n  <IconStatusBubble\n    color=\"danger\"\n    header={\n      <React.Fragment>\n        <Typography weight=\"bold\">Something cool happened</Typography>\n        <Typography volume=\"quiet\">Yesterday</Typography>\n      </React.Fragment>\n    }\n    icon={<PhorkIcon scale=\"large\" />}\n  >\n    <Paper color=\"primary\">\n      <Rhythm p={4}>Hello world</Rhythm>\n    </Paper>\n  </IconStatusBubble>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PhorkIcon,
      Divider,
      Paper,
      Rhythm,
      Typography,
      PageTitle,
      ThemeWrapper,
      IconStatusBubble,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <IconStatusBubble color="danger" header={<React.Fragment>
          <Typography weight="bold" mdxType="Typography">Something cool happened</Typography>
          <Typography volume="quiet" mdxType="Typography">Yesterday</Typography>
        </React.Fragment>} icon={<PhorkIcon scale="large" mdxType="PhorkIcon" />} mdxType="IconStatusBubble">
      <Paper color="primary" mdxType="Paper">
        <Rhythm p={4} mdxType="Rhythm">Hello world</Rhythm>
      </Paper>
    </IconStatusBubble>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "right-top"
    }}>{`Right top`}</h2>
    <Playground __position={6} __code={'<ThemeWrapper>\n  <IconStatusBubble\n    header={\n      <React.Fragment>\n        <Typography weight=\"bold\">Something cool happened</Typography>\n        <Typography volume=\"quiet\">Yesterday</Typography>\n      </React.Fragment>\n    }\n    icon={<PhorkIcon scale=\"large\" />}\n    position=\"right-top\"\n  >\n    <Paper color=\"primary\">\n      <Rhythm p={4}>Hello world</Rhythm>\n    </Paper>\n  </IconStatusBubble>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PhorkIcon,
      Divider,
      Paper,
      Rhythm,
      Typography,
      PageTitle,
      ThemeWrapper,
      IconStatusBubble,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <IconStatusBubble header={<React.Fragment>
          <Typography weight="bold" mdxType="Typography">Something cool happened</Typography>
          <Typography volume="quiet" mdxType="Typography">Yesterday</Typography>
        </React.Fragment>} icon={<PhorkIcon scale="large" mdxType="PhorkIcon" />} position="right-top" mdxType="IconStatusBubble">
      <Paper color="primary" mdxType="Paper">
        <Rhythm p={4} mdxType="Rhythm">Hello world</Rhythm>
      </Paper>
    </IconStatusBubble>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "left-top"
    }}>{`Left top`}</h2>
    <Playground __position={7} __code={'<ThemeWrapper>\n  <IconStatusBubble\n    header={\n      <React.Fragment>\n        <Typography weight=\"bold\">Something cool happened</Typography>\n        <Typography volume=\"quiet\">Yesterday</Typography>\n      </React.Fragment>\n    }\n    icon={<PhorkIcon scale=\"large\" />}\n    position=\"left-top\"\n  >\n    <Paper color=\"primary\">\n      <Rhythm p={4}>Hello world</Rhythm>\n    </Paper>\n  </IconStatusBubble>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PhorkIcon,
      Divider,
      Paper,
      Rhythm,
      Typography,
      PageTitle,
      ThemeWrapper,
      IconStatusBubble,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <IconStatusBubble header={<React.Fragment>
          <Typography weight="bold" mdxType="Typography">Something cool happened</Typography>
          <Typography volume="quiet" mdxType="Typography">Yesterday</Typography>
        </React.Fragment>} icon={<PhorkIcon scale="large" mdxType="PhorkIcon" />} position="left-top" mdxType="IconStatusBubble">
      <Paper color="primary" mdxType="Paper">
        <Rhythm p={4} mdxType="Rhythm">Hello world</Rhythm>
      </Paper>
    </IconStatusBubble>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "right-center"
    }}>{`Right center`}</h2>
    <Playground __position={8} __code={'<ThemeWrapper>\n  <IconStatusBubble\n    header={\n      <React.Fragment>\n        <Typography weight=\"bold\">Something cool happened</Typography>\n        <Typography volume=\"quiet\">Yesterday</Typography>\n      </React.Fragment>\n    }\n    icon={<PhorkIcon scale=\"large\" />}\n    position=\"right-center\"\n  >\n    <Paper color=\"primary\">\n      <Rhythm p={4}>Hello world</Rhythm>\n    </Paper>\n  </IconStatusBubble>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PhorkIcon,
      Divider,
      Paper,
      Rhythm,
      Typography,
      PageTitle,
      ThemeWrapper,
      IconStatusBubble,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <IconStatusBubble header={<React.Fragment>
          <Typography weight="bold" mdxType="Typography">Something cool happened</Typography>
          <Typography volume="quiet" mdxType="Typography">Yesterday</Typography>
        </React.Fragment>} icon={<PhorkIcon scale="large" mdxType="PhorkIcon" />} position="right-center" mdxType="IconStatusBubble">
      <Paper color="primary" mdxType="Paper">
        <Rhythm p={4} mdxType="Rhythm">Hello world</Rhythm>
      </Paper>
    </IconStatusBubble>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "left-center"
    }}>{`Left center`}</h2>
    <Playground __position={9} __code={'<ThemeWrapper>\n  <IconStatusBubble\n    header={\n      <React.Fragment>\n        <Typography weight=\"bold\">Something cool happened</Typography>\n        <Typography volume=\"quiet\">Yesterday</Typography>\n      </React.Fragment>\n    }\n    icon={<PhorkIcon scale=\"large\" />}\n    position=\"left-center\"\n  >\n    <Paper color=\"primary\">\n      <Rhythm p={4}>Hello world</Rhythm>\n    </Paper>\n  </IconStatusBubble>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PhorkIcon,
      Divider,
      Paper,
      Rhythm,
      Typography,
      PageTitle,
      ThemeWrapper,
      IconStatusBubble,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <IconStatusBubble header={<React.Fragment>
          <Typography weight="bold" mdxType="Typography">Something cool happened</Typography>
          <Typography volume="quiet" mdxType="Typography">Yesterday</Typography>
        </React.Fragment>} icon={<PhorkIcon scale="large" mdxType="PhorkIcon" />} position="left-center" mdxType="IconStatusBubble">
      <Paper color="primary" mdxType="Paper">
        <Rhythm p={4} mdxType="Rhythm">Hello world</Rhythm>
      </Paper>
    </IconStatusBubble>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "right-bottom"
    }}>{`Right bottom`}</h2>
    <Playground __position={10} __code={'<ThemeWrapper>\n  <IconStatusBubble\n    header={\n      <React.Fragment>\n        <Typography weight=\"bold\">Something cool happened</Typography>\n        <Typography volume=\"quiet\">Yesterday</Typography>\n      </React.Fragment>\n    }\n    icon={<PhorkIcon scale=\"large\" />}\n    position=\"right-bottom\"\n  >\n    <Paper color=\"primary\">\n      <Rhythm p={4}>Hello world</Rhythm>\n    </Paper>\n  </IconStatusBubble>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PhorkIcon,
      Divider,
      Paper,
      Rhythm,
      Typography,
      PageTitle,
      ThemeWrapper,
      IconStatusBubble,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <IconStatusBubble header={<React.Fragment>
          <Typography weight="bold" mdxType="Typography">Something cool happened</Typography>
          <Typography volume="quiet" mdxType="Typography">Yesterday</Typography>
        </React.Fragment>} icon={<PhorkIcon scale="large" mdxType="PhorkIcon" />} position="right-bottom" mdxType="IconStatusBubble">
      <Paper color="primary" mdxType="Paper">
        <Rhythm p={4} mdxType="Rhythm">Hello world</Rhythm>
      </Paper>
    </IconStatusBubble>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "left-bottom"
    }}>{`Left bottom`}</h2>
    <Playground __position={11} __code={'<ThemeWrapper>\n  <IconStatusBubble\n    header={\n      <React.Fragment>\n        <Typography weight=\"bold\">Something cool happened</Typography>\n        <Typography volume=\"quiet\">Yesterday</Typography>\n      </React.Fragment>\n    }\n    icon={<PhorkIcon scale=\"large\" />}\n    position=\"left-bottom\"\n  >\n    <Paper color=\"primary\">\n      <Rhythm p={4}>Hello world</Rhythm>\n    </Paper>\n  </IconStatusBubble>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PhorkIcon,
      Divider,
      Paper,
      Rhythm,
      Typography,
      PageTitle,
      ThemeWrapper,
      IconStatusBubble,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <IconStatusBubble header={<React.Fragment>
          <Typography weight="bold" mdxType="Typography">Something cool happened</Typography>
          <Typography volume="quiet" mdxType="Typography">Yesterday</Typography>
        </React.Fragment>} icon={<PhorkIcon scale="large" mdxType="PhorkIcon" />} position="left-bottom" mdxType="IconStatusBubble">
      <Paper color="primary" mdxType="Paper">
        <Rhythm p={4} mdxType="Rhythm">Hello world</Rhythm>
      </Paper>
    </IconStatusBubble>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "no-header"
    }}>{`No header`}</h2>
    <Playground __position={12} __code={'<ThemeWrapper>\n  <IconStatusBubble\n    color=\"primary\"\n    icon={<PhorkIcon scale=\"large\" />}\n    style={{ maxWidth: 400 }}\n  >\n    <React.Fragment>\n      <Paper color=\"primary\">\n        <Rhythm grouped px={4} py={3}>\n          <div>Hello world</div>\n          <div>Hello world</div>\n          <div>Hello world</div>\n        </Rhythm>\n      </Paper>\n      <Divider volume=\"quieter\" />\n      <Paper color=\"secondary\">\n        <Rhythm grouped px={4} py={3}>\n          <div>Hello world</div>\n        </Rhythm>\n      </Paper>\n    </React.Fragment>\n  </IconStatusBubble>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PhorkIcon,
      Divider,
      Paper,
      Rhythm,
      Typography,
      PageTitle,
      ThemeWrapper,
      IconStatusBubble,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <IconStatusBubble color="primary" icon={<PhorkIcon scale="large" mdxType="PhorkIcon" />} style={{
          maxWidth: 400
        }} mdxType="IconStatusBubble">
      <React.Fragment>
        <Paper color="primary" mdxType="Paper">
          <Rhythm grouped px={4} py={3} mdxType="Rhythm">
            <div>Hello world</div>
            <div>Hello world</div>
            <div>Hello world</div>
          </Rhythm>
        </Paper>
        <Divider volume="quieter" mdxType="Divider" />
        <Paper color="secondary" mdxType="Paper">
          <Rhythm grouped px={4} py={3} mdxType="Rhythm">
            <div>Hello world</div>
          </Rhythm>
        </Paper>
      </React.Fragment>
    </IconStatusBubble>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "no-children"
    }}>{`No children`}</h2>
    <Playground __position={13} __code={'<ThemeWrapper>\n  <IconStatusBubble\n    color=\"neutral\"\n    header={\n      <React.Fragment>\n        <Typography weight=\"bold\">Something cool happened</Typography>\n        <Typography volume=\"quiet\">Yesterday</Typography>\n      </React.Fragment>\n    }\n    icon={<PhorkIcon scale=\"large\" />}\n    style={{ maxWidth: 400 }}\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PhorkIcon,
      Divider,
      Paper,
      Rhythm,
      Typography,
      PageTitle,
      ThemeWrapper,
      IconStatusBubble,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <IconStatusBubble color="neutral" header={<React.Fragment>
          <Typography weight="bold" mdxType="Typography">Something cool happened</Typography>
          <Typography volume="quiet" mdxType="Typography">Yesterday</Typography>
        </React.Fragment>} icon={<PhorkIcon scale="large" mdxType="PhorkIcon" />} style={{
          maxWidth: 400
        }} mdxType="IconStatusBubble" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "no-triangle"
    }}>{`No triangle`}</h2>
    <Playground __position={14} __code={'<ThemeWrapper>\n  <IconStatusBubble\n    color=\"neutral\"\n    header={\n      <React.Fragment>\n        <Typography weight=\"bold\">Something cool happened</Typography>\n        <Typography volume=\"quiet\">Yesterday</Typography>\n      </React.Fragment>\n    }\n    icon={<PhorkIcon scale=\"large\" />}\n    style={{ maxWidth: 400 }}\n    triangleBorderColor=\"transparent\"\n    triangleColor=\"transparent\"\n  >\n    <Paper color=\"primary\">\n      <Rhythm p={4}>Hello world</Rhythm>\n    </Paper>\n  </IconStatusBubble>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PhorkIcon,
      Divider,
      Paper,
      Rhythm,
      Typography,
      PageTitle,
      ThemeWrapper,
      IconStatusBubble,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <IconStatusBubble color="neutral" header={<React.Fragment>
          <Typography weight="bold" mdxType="Typography">Something cool happened</Typography>
          <Typography volume="quiet" mdxType="Typography">Yesterday</Typography>
        </React.Fragment>} icon={<PhorkIcon scale="large" mdxType="PhorkIcon" />} style={{
          maxWidth: 400
        }} triangleBorderColor="transparent" triangleColor="transparent" mdxType="IconStatusBubble">
      <Paper color="primary" mdxType="Paper">
        <Rhythm p={4} mdxType="Rhythm">Hello world</Rhythm>
      </Paper>
    </IconStatusBubble>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "props"
    }}>{`[`}{`props`}{`]`}</h2>
    <Props of={IconStatusBubble} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      